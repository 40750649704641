<template>
    <admin-dashboard-layout>
        <v-row>
            <v-col class="text-right">
                <v-btn color="grey" :to="{name: 'admin.cities.create'}">Create</v-btn>
            </v-col>
        </v-row>
        <v-data-table
            :loading="loading"
            loading-text="Loading... Please wait"
            :headers="headers"
            :items="cities"
            :items-per-page="15"
            class="elevation-1"
        >
            <template v-slot:item.name_ru="{ item }">
                <v-row class="cursor-pointer text--blue">
                    <v-col @click="handleClickName(item)">
                        {{ item.name_ru }}
                    </v-col>
                </v-row>
            </template>
            <template v-slot:item.image_url="{ item }">
                <v-row v-if="item.image_url" class="cursor-pointer text--blue">
                    <v-col>
                        <img :src="`${item.image_url}`" alt="City photo" class="max-width150"/>
                    </v-col>
                </v-row>
                <p v-else>No image</p>
            </template>
            <template v-slot:item.created_at="{ item }">
                {{ new Date(item.created_at).toLocaleString() }} <!--TODO add day.js and default format -->
            </template>
            <template v-slot:item.actions="{ item }">
                <city-delete-dialog @deleted="handleRemoveCity" :city="item" />
            </template>
        </v-data-table>
    </admin-dashboard-layout>
</template>

<script>
import City from '../../../models/City';
import AdminDashboardLayout from "../../../layouts/AdminDashboardLayout";
import CityDeleteDialog from "../../../components/dialogs/CityDeleteDialog";


export default {
    name: "cities-index",
    components: {AdminDashboardLayout,CityDeleteDialog},
    data: () => (
        {
            loading: false,
            cities: [],
            headers: [
                {
                    text: '# ID',
                    align: 'start',
                    sortable: false,
                    value: 'id',
                },
                {text: 'Name RU', value: 'name_ru'},
                {text: 'Name EN', value: 'name_en'},
                {text: 'Name HY', value: 'name_hy'},
                {text: 'Picture', value: 'image_url'},
                {text: 'Created at', value: 'created_at'},
                {text: 'Actions', value: 'actions'},
            ],
        }
    ),
    methods: {
        handleClickName(city) {
            this.$router.push({name: 'admin.cities.show', params: {id: city.id}})
        },
        handleRemoveCity(id) {
            this.cities = this.$_.filter(this.cities, city => city.id !== id);
        }
    },
    async mounted() {
        this.loading = true
        this.cities = await City.get()
        this.loading = false;
    },
}
</script>

<style scoped>
.cursor-pointer {
    cursor: pointer;
}

.text--blue {
    color: #0d47a1;
}

.text--blue:hover {
    text-decoration: underline;
}

.max-width150{
    max-width: 150px;
}
</style>
