var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('admin-dashboard-layout',[_c('v-row',[_c('v-col',{staticClass:"text-right"},[_c('v-btn',{attrs:{"color":"grey","to":{name: 'admin.cities.create'}}},[_vm._v("Create")])],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"loading":_vm.loading,"loading-text":"Loading... Please wait","headers":_vm.headers,"items":_vm.cities,"items-per-page":15},scopedSlots:_vm._u([{key:"item.name_ru",fn:function(ref){
var item = ref.item;
return [_c('v-row',{staticClass:"cursor-pointer text--blue"},[_c('v-col',{on:{"click":function($event){return _vm.handleClickName(item)}}},[_vm._v(" "+_vm._s(item.name_ru)+" ")])],1)]}},{key:"item.image_url",fn:function(ref){
var item = ref.item;
return [(item.image_url)?_c('v-row',{staticClass:"cursor-pointer text--blue"},[_c('v-col',[_c('img',{staticClass:"max-width150",attrs:{"src":("" + (item.image_url)),"alt":"City photo"}})])],1):_c('p',[_vm._v("No image")])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item.created_at).toLocaleString())+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('city-delete-dialog',{attrs:{"city":item},on:{"deleted":_vm.handleRemoveCity}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }